<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <TiktokData/>
      </div>
    </PageHeaderLayout>
  </div>

</template>

<script>
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import TiktokData from "./TiktokData"
import { mapGetters } from "vuex";

export default {
  name: 'CaseRecordDataList',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    PageHeaderLayout,  TiktokData
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
